body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: Pepi;
  src: url(/fonts/PepiMedium.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Pepi;
  src: url(/fonts/PepiLight.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
}

canvas {
  cursor: pointer;
}

html {
  font-size: calc(1em + 0.5vw);
  color: #1b1b1b;
  font-family: Pepi, sans-serif;
}

#logos img {
  max-width: 100%;
  height: 2em;
}

.progress {
  height: 1.5rem;
}

.progress-bar {
  padding: 1em;
}

